<template>
  <div>
    <img src="/logo-white.svg" alt="" />
  </div>
</template>

<style scoped>
img {
  width: 120px;
  max-height: 85px;
}
</style>
