import { default as indexqqF5mDs889Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/[id]/index.vue?macro=true";
import { default as payoutsTK1uVlWjb3Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/[id]/payouts.vue?macro=true";
import { default as statsftiDnhk1b9Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/[id]/stats.vue?macro=true";
import { default as addH41z2eEmLcMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/add.vue?macro=true";
import { default as indexc0CnBtuuQ1Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/index.vue?macro=true";
import { default as indexJ941avFUImMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/deploy/index.vue?macro=true";
import { default as indexkcSRaMIIhIMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/[id]/event-manager/index.vue?macro=true";
import { default as indexOmhjVu09u8Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/[id]/index.vue?macro=true";
import { default as addWHKJYqXcKoMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/add.vue?macro=true";
import { default as indexgYsjID2mltMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/index.vue?macro=true";
import { default as index4o1DfS3iWcMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/index.vue?macro=true";
import { default as indexXzqsWUHJEeMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/order/[id]/index.vue?macro=true";
import { default as indexRKnPu6T2VGMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/order/index.vue?macro=true";
import { default as indexDX3r76G9YoMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/pages/[id]/index.vue?macro=true";
import { default as addsze5SW9m4vMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/pages/add.vue?macro=true";
import { default as indexEToL95JAzbMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/pages/index.vue?macro=true";
import { default as indexiXx0F1NAg3Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/payouts/index.vue?macro=true";
import { default as indexkzJwC1wqbZMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/place/[id]/index.vue?macro=true";
import { default as addNX8LTxevC5Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/place/add.vue?macro=true";
import { default as indexNl5WUS8vglMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/place/index.vue?macro=true";
import { default as indexwCSOOOpDDaMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/requests/index.vue?macro=true";
import { default as design98zeOsyb81Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/design.vue?macro=true";
import { default as emailsjd4wn01MfMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/email.vue?macro=true";
import { default as indexXMcZCVXUVyMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/index.vue?macro=true";
import { default as emailChl2RCfYAfMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/email.vue?macro=true";
import { default as indexgoHAeqBiKEMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/index.vue?macro=true";
import { default as smsaCTKQZ7IyuMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/sms.vue?macro=true";
import { default as whatsappGEUkbDxXUTMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/whatsapp.vue?macro=true";
import { default as indexTNqGA8W6UdMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/sponsor/[id]/index.vue?macro=true";
import { default as adduc9V4ATf0QMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/sponsor/add.vue?macro=true";
import { default as indexIsgnxgJKsXMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/sponsor/index.vue?macro=true";
import { default as indexxZEOvpLoDmMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/user/[id]/index.vue?macro=true";
import { default as addGPi7GoOk0qMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/user/add.vue?macro=true";
import { default as index1No3BCyGC7Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/user/index.vue?macro=true";
import { default as archivevxRAIazvm3Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/archive.vue?macro=true";
import { default as cancelSF7L99St5HMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/checkout/cancel.vue?macro=true";
import { default as indexf9KiVEaBksMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/checkout/index.vue?macro=true";
import { default as successat7qvy6bQ2Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/checkout/success.vue?macro=true";
import { default as index4WNbNVKRutMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/event/index.vue?macro=true";
import { default as indexMwnh8TFELeMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/index.vue?macro=true";
import { default as add2s7SVnS2TfMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/payouts/add.vue?macro=true";
import { default as indexrCmylS3b49Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/payouts/index.vue?macro=true";
import { default as settingsnBStfMolpLMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/settings.vue?macro=true";
import { default as collectlkUmfII4uAMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/collect.vue?macro=true";
import { default as index0MjlgKdrscMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/event/[slug]/index.vue?macro=true";
import { default as indexF7JbtxiuuhMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/event/genre/index.vue?macro=true";
import { default as indexOZAXsrEVELMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/event/tag/index.vue?macro=true";
import { default as indexULt3LdA28EMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/event/type/index.vue?macro=true";
import { default as indexP0ot3HOwgnMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/index.vue?macro=true";
import { default as loginRLepVIKM9mMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/login.vue?macro=true";
import { default as indexw9ulpcpS3FMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/page/[slug]/index.vue?macro=true";
import { default as indexmZkhNFCW7XMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/payspot/[id]/index.vue?macro=true";
import { default as indexW7W3PyjuxUMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/payspot/event/[id]/index.vue?macro=true";
import { default as indexNaK39iw2O5Meta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/scanner/[id]/index.vue?macro=true";
import { default as supportnlOwybjn1RMeta } from "/etc/nginx/sites-available/tickets.spandiva.com/pages/scanner/support.vue?macro=true";
export default [
  {
    name: "admin-client-id",
    path: "/admin/client/:id()",
    meta: indexqqF5mDs889Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/[id]/index.vue")
  },
  {
    name: "admin-client-id-payouts",
    path: "/admin/client/:id()/payouts",
    meta: payoutsTK1uVlWjb3Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/[id]/payouts.vue")
  },
  {
    name: "admin-client-id-stats",
    path: "/admin/client/:id()/stats",
    meta: statsftiDnhk1b9Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/[id]/stats.vue")
  },
  {
    name: "admin-client-add",
    path: "/admin/client/add",
    meta: addH41z2eEmLcMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/add.vue")
  },
  {
    name: "admin-client",
    path: "/admin/client",
    meta: indexc0CnBtuuQ1Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/client/index.vue")
  },
  {
    name: "admin-deploy",
    path: "/admin/deploy",
    meta: indexJ941avFUImMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/deploy/index.vue")
  },
  {
    name: "admin-event-id-event-manager",
    path: "/admin/event/:id()/event-manager",
    meta: indexkcSRaMIIhIMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/[id]/event-manager/index.vue")
  },
  {
    name: "admin-event-id",
    path: "/admin/event/:id()",
    meta: indexOmhjVu09u8Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/[id]/index.vue")
  },
  {
    name: "admin-event-add",
    path: "/admin/event/add",
    meta: addWHKJYqXcKoMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/add.vue")
  },
  {
    name: "admin-event",
    path: "/admin/event",
    meta: indexgYsjID2mltMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/event/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: index4o1DfS3iWcMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/index.vue")
  },
  {
    name: "admin-order-id",
    path: "/admin/order/:id()",
    meta: indexXzqsWUHJEeMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/order/[id]/index.vue")
  },
  {
    name: "admin-order",
    path: "/admin/order",
    meta: indexRKnPu6T2VGMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/order/index.vue")
  },
  {
    name: "admin-pages-id",
    path: "/admin/pages/:id()",
    meta: indexDX3r76G9YoMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/pages/[id]/index.vue")
  },
  {
    name: "admin-pages-add",
    path: "/admin/pages/add",
    meta: addsze5SW9m4vMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/pages/add.vue")
  },
  {
    name: "admin-pages",
    path: "/admin/pages",
    meta: indexEToL95JAzbMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/pages/index.vue")
  },
  {
    name: "admin-payouts",
    path: "/admin/payouts",
    meta: indexiXx0F1NAg3Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/payouts/index.vue")
  },
  {
    name: "admin-place-id",
    path: "/admin/place/:id()",
    meta: indexkzJwC1wqbZMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/place/[id]/index.vue")
  },
  {
    name: "admin-place-add",
    path: "/admin/place/add",
    meta: addNX8LTxevC5Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/place/add.vue")
  },
  {
    name: "admin-place",
    path: "/admin/place",
    meta: indexNl5WUS8vglMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/place/index.vue")
  },
  {
    name: "admin-requests",
    path: "/admin/requests",
    meta: indexwCSOOOpDDaMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/requests/index.vue")
  },
  {
    name: "admin-settings-design",
    path: "/admin/settings/design",
    meta: design98zeOsyb81Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/design.vue")
  },
  {
    name: "admin-settings-email",
    path: "/admin/settings/email",
    meta: emailsjd4wn01MfMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/email.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: indexXMcZCVXUVyMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/index.vue")
  },
  {
    name: "admin-settings-marketing-email",
    path: "/admin/settings/marketing/email",
    meta: emailChl2RCfYAfMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/email.vue")
  },
  {
    name: "admin-settings-marketing",
    path: "/admin/settings/marketing",
    meta: indexgoHAeqBiKEMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/index.vue")
  },
  {
    name: "admin-settings-marketing-sms",
    path: "/admin/settings/marketing/sms",
    meta: smsaCTKQZ7IyuMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/sms.vue")
  },
  {
    name: "admin-settings-marketing-whatsapp",
    path: "/admin/settings/marketing/whatsapp",
    meta: whatsappGEUkbDxXUTMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/settings/marketing/whatsapp.vue")
  },
  {
    name: "admin-sponsor-id",
    path: "/admin/sponsor/:id()",
    meta: indexTNqGA8W6UdMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/sponsor/[id]/index.vue")
  },
  {
    name: "admin-sponsor-add",
    path: "/admin/sponsor/add",
    meta: adduc9V4ATf0QMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/sponsor/add.vue")
  },
  {
    name: "admin-sponsor",
    path: "/admin/sponsor",
    meta: indexIsgnxgJKsXMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/sponsor/index.vue")
  },
  {
    name: "admin-user-id",
    path: "/admin/user/:id()",
    meta: indexxZEOvpLoDmMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/user/[id]/index.vue")
  },
  {
    name: "admin-user-add",
    path: "/admin/user/add",
    meta: addGPi7GoOk0qMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/user/add.vue")
  },
  {
    name: "admin-user",
    path: "/admin/user",
    meta: index1No3BCyGC7Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/admin/user/index.vue")
  },
  {
    name: "archive",
    path: "/archive",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/archive.vue")
  },
  {
    name: "checkout-cancel",
    path: "/checkout/cancel",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/checkout/cancel.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexf9KiVEaBksMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/checkout/success.vue")
  },
  {
    name: "client-id-event",
    path: "/client/:id()/event",
    meta: index4WNbNVKRutMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/event/index.vue")
  },
  {
    name: "client-id",
    path: "/client/:id()",
    meta: indexMwnh8TFELeMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/index.vue")
  },
  {
    name: "client-id-payouts-add",
    path: "/client/:id()/payouts/add",
    meta: add2s7SVnS2TfMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/payouts/add.vue")
  },
  {
    name: "client-id-payouts",
    path: "/client/:id()/payouts",
    meta: indexrCmylS3b49Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/payouts/index.vue")
  },
  {
    name: "client-id-settings",
    path: "/client/:id()/settings",
    meta: settingsnBStfMolpLMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/client/[id]/settings.vue")
  },
  {
    name: "collect",
    path: "/collect",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/collect.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/event/[slug]/index.vue")
  },
  {
    name: "event-genre",
    path: "/event/genre",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/event/genre/index.vue")
  },
  {
    name: "event-tag",
    path: "/event/tag",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/event/tag/index.vue")
  },
  {
    name: "event-type",
    path: "/event/type",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/event/type/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRLepVIKM9mMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/login.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/page/[slug]/index.vue")
  },
  {
    name: "payspot-id",
    path: "/payspot/:id()",
    meta: indexmZkhNFCW7XMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/payspot/[id]/index.vue")
  },
  {
    name: "payspot-event-id",
    path: "/payspot/event/:id()",
    meta: indexW7W3PyjuxUMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/payspot/event/[id]/index.vue")
  },
  {
    name: "scanner-id",
    path: "/scanner/:id()",
    meta: indexNaK39iw2O5Meta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/scanner/[id]/index.vue")
  },
  {
    name: "scanner-support",
    path: "/scanner/support",
    meta: supportnlOwybjn1RMeta || {},
    component: () => import("/etc/nginx/sites-available/tickets.spandiva.com/pages/scanner/support.vue")
  }
]