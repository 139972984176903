import * as pkg from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(pkg.default, {
    // position: pkg.POSITION.TOP_CENTER,
    position: 'bottom-center',
    timeout: 2500,
    maxToasts: 1,
  })
})
