<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <NuxtLink to="/">Go back to Home</NuxtLink>
  </div>
</template>

<script setup>
useHead({
  title: '404 - Page Not Found',
  meta: [{ name: 'description', content: 'Page not found - 404 Error' }],
})
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
}
</style>
