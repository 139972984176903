// https://github.com/nuxt/nuxt/discussions/15961#discussioncomment-2416005
//? Modified native $fetch to include headers

type HeadersType = Record<string, string>

// Simplified type for options
type FetchOptions = {
  method?: string
  headers?: HeadersType
  body?: any
}

export const useFetchAuth = (url: string, opts?: any): Promise<any> => {
  const _vh_user_token = useCookie('_vh_user_token').value // Assuming the token is stored in a cookie

  const headers: FetchOptions = {
    ...(opts?.headers || {}),
    ...(_vh_user_token && { Authorization: `Bearer ${_vh_user_token || ''}` }),
  }

  return $fetch(url, { ...opts, headers })
}
