import { type IEmailSettings } from '~~/types/index'
import { type IPaymentSettings } from '~~/types/index'

import { type IError } from '~~/types/index'
import { useFetchAuth } from '~~/composables/useFetchAuth'

export function useSettingsStore() {
  // Get email settings
  const getEmailSettings = async () => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/email/get`, {
        method: 'POST',
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  // Create email settings
  const createEmailSettings = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/email/create`, {
        method: 'POST',
        body: body,
      })
      return data as IEmailSettings
    } catch (e) {
      return e as IError
    }
  }

  // Create email settings
  const testEmailSettings = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/email/test`, {
        method: 'POST',
        body: body,
      })
      return data as IEmailSettings
    } catch (e) {
      return e as IError
    }
  }

  const createGlobalSettings = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/global/create`, {
        method: 'POST',
        body: body,
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  // Get global settings
  const getGlobalSettings = async () => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/global/get`, {
        method: 'POST',
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  // Update global settings
  const updateGlobalSettings = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/global/update`, {
        method: 'POST',
        body: body,
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  const createPaymentSettings = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/payments/create`, {
        method: 'POST',
        body: body,
      })
      return data as IPaymentSettings
    } catch (e) {
      return e as IError
    }
  }

  const updatePaymentSettings = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/payments/update`, {
        method: 'POST',
        body: body,
      })
      return data as IPaymentSettings
    } catch (e) {
      return e as IError
    }
  }

  const getPaymentSettings = async () => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/payments/get`, {
        method: 'POST',
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  const createDesign = async (body: {}) => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/design/create`, {
        method: 'POST',
        body: body,
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  const getDesign = async () => {
    try {
      const data = await useFetchAuth(`/api/v1/back/settings/design/get`, {
        method: 'POST',
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  return {
    getEmailSettings,
    createEmailSettings,
    testEmailSettings,
    createGlobalSettings,
    getGlobalSettings,
    updateGlobalSettings,
    createPaymentSettings,
    updatePaymentSettings,
    getPaymentSettings,
    createDesign,
    getDesign,
  }
}
