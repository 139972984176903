// scrollTop.client.ts
export default defineNuxtPlugin({
  name: 'scroll-client',
  hooks: {
    'page:finish': () => {
      if (!useRouter().currentRoute.value.meta.scrollToTop) return

      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0
      }
    },
  },
})
