// Define the type for the design settings
interface DesignSettings {
  primaryColor: string
  secondaryColor: string
  textColor: string
  whiteColor: string
  grayColor: string
  grayBgColor: string
  blackColor: string
  linkColor: string
  linkHoverColor: string
  borderColor: string
  backgroundColor: string
  headerBgColor: string
  footerBgColor: string
  buttonBgColor: string
  buttonTextColor: string
  redColor: string
  greenColor: string
  headerTextColor: string
  headerTextColorHover: string
  footerTextColor: string
  footerTextColorHover: string
  sectionBgColor: string
  fontFamily: string
}

// Create inline styles inside head
export default defineNuxtPlugin(async (nuxtApp) => {
  const settingsStore = useSettingsStore()

  try {
    // Fetch the value from the database
    const stylesData: { data: DesignSettings } = await settingsStore.getDesign()
    const styleElement = document.createElement('style')

    // Set the id and type for the style element
    styleElement.id = 'generated-styles'
    styleElement.type = 'text/css'

    // Create CSS variables and @font-face rules as a string
    const cssVariables = `
      :root {
        --primary-color: ${stylesData.data.primaryColor};
        --secondary-color: ${stylesData.data.secondaryColor};
        --text: ${stylesData.data.textColor};
        --white: ${stylesData.data.whiteColor};
        --gray: ${stylesData.data.grayColor};
        --gray-bg: ${stylesData.data.grayBgColor};
        --black: ${stylesData.data.blackColor};
        --link: ${stylesData.data.linkColor};
        --link-hover: ${stylesData.data.linkHoverColor};
        --border: ${stylesData.data.borderColor};
        --background: ${stylesData.data.backgroundColor};
        --header-bg: ${stylesData.data.headerBgColor};
        --footer-bg: ${stylesData.data.footerBgColor};
        --button-bg: ${stylesData.data.buttonBgColor};
        --button-text: ${stylesData.data.buttonTextColor};
        --red: ${stylesData.data.redColor};
        --green: ${stylesData.data.greenColor};
        --header-bg-color: ${stylesData.data.headerBgColor};
        --header-text-color: ${stylesData.data.headerTextColor};
        --header-text-color-hover: ${stylesData.data.headerTextColorHover};
        --footer-bg-color: ${stylesData.data.footerBgColor};
        --footer-text-color: ${stylesData.data.footerTextColor};
        --footer-text-color-hover: ${stylesData.data.footerTextColorHover};
        --section-bg-color: ${stylesData.data.sectionBgColor};
        --font-family: ${stylesData.data.fontFamily};
        --font-bold: ${stylesData.data.fontFamily}-Bold;
        --font-regular: ${stylesData.data.fontFamily}-Regular;
        --font-light: ${stylesData.data.fontFamily}-Light;
      }

      @font-face {
        font-family: '${stylesData.data.fontFamily}-Light';
        src: url('/fonts/${stylesData.data.fontFamily}-Light.ttf') format('truetype');
        font-weight: normal;
      }
      @font-face {
        font-family: '${stylesData.data.fontFamily}-Regular';
        src: url('/fonts/${stylesData.data.fontFamily}-Regular.ttf') format('truetype');
        font-weight: normal;
      }
      @font-face {
        font-family: '${stylesData.data.fontFamily}-Bold';
        src: url('/fonts/${stylesData.data.fontFamily}-Bold.ttf') format('truetype');
        font-weight: bold;
      }
    `

    // Set the CSS content of the style element
    styleElement.textContent = cssVariables

    // Append the style element to the head
    document.head.appendChild(styleElement)
  } catch (error) {
    console.error('Failed to fetch design settings:', error)
  }
})
